/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-29",
    versionChannel: "nightly",
    buildDate: "2024-10-29T00:06:24.980Z",
    commitHash: "f71417284aad620314a82ca3efa92a2397ad254d",
};
